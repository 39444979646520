<template>
  <div>
    <Close />
    <b-carousel
      class="photos-carousel"
      :animated="animated"
      :autoplay="autoPlay"
      :indicator="indicator"
      :indicator-background="indicatorBackground"
      :indicator-inside="indicatorInside"
      :indicator-mode="indicatorMode"
      :indicator-position="indicatorPosition"
      :indicator-style="indicatorStyle"
      :arrow="arrow"
      @change="loading($event)"
    >
      <b-carousel-item v-for="(carousel, i) in carousels[roomId]" :key="i">
        <span
          class="image"
          :style="`background-image: url(${carousel.img})`"
          draggable="true"
        >
        </span>
        <span class="image-gradient"></span>
        <span class="image-description">{{ carousel.description }}</span>
      </b-carousel-item>
    </b-carousel>
    <b-loading
      :is-full-page="isFullPage"
      v-model="isLoading"
      :can-cancel="true"
    ></b-loading>
  </div>
</template>

<script>
import Close from '@/components/Close'

export default {
  components: {
    Close
  },
  data () {
    return {
      isLoading: false,
      isFullPage: true,
      animated: 'slide',
      autoPlay: false,
      arrow: false,
      indicator: true,
      indicatorBackground: true,
      indicatorInside: true,
      indicatorMode: 'click',
      indicatorPosition: 'is-bottom',
      indicatorStyle: 'is-dots',
      carousels: {
        1: [
          {
            img: `${require('@/assets/reading_room/room-reading.jpg')}`,
            description: this.$i18n.t('rooms.1.img_1')
          }
        ],
        2: [
          {
            img: `${require('@/assets/attached_room/room-attached.jpg')}`,
            description: this.$i18n.t('rooms.3.img_1')
          }
        ],
        3: [
          {
            img: `${require('@/assets/multimedia_room/room-multimedia.jpg')}`,
            description: this.$i18n.t('rooms.3.img_1')
          }
        ]
      }
    }
  },
  computed: {
    roomId () {
      return this.$store.state.activeRoom
    }
  },
  mounted () {
    const element = document.querySelector('.image')
    this.imageLoading(element)
  },
  methods: {
    // Shows loading overlay until image is loaded
    imageLoading (element) {
      this.isLoading = true
      const img = new Image()
      const bgStyle = element.style.backgroundImage
      const src = bgStyle.slice(4, -1).replace(/"/g, '')
      img.src = src
      img.addEventListener('load', () => {
        this.isLoading = false
      })
    },
    loading (value) {
      this.carousel = value
      this.imageLoading(document.querySelectorAll('.image')[value])
    }
  }
}
</script>
